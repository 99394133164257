import React, { useCallback, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { SalesData } from '../network';
import { useUpdateUsers, useUsers } from '../hooks';

const styles = {
  container: {
    height: '80vh',
    margin: '0 auto',
    backgroundColor: '#E7E6E6',
    borderRadius: '20px',
    position: 'relative',
    width: 'calc(100% - 30px)',
    fontFamily:'IBM Plex Sans, sans-serif'
  },
  rounded: {
    width: '26px',
    height: '26px',
    backgroundColor: 'white',
    borderRadius: '50%',
    position: 'absolute',
    fontFamily:'IBM Plex Sans, sans-serif'
  },
  headerTwo: {
    fontWeight: 'bold',
    marginBottom: '5px',
    fontFamily:'IBM Plex Sans, sans-serif'
  },
  normalText: {
    fontSize: '12px',
    marginBottom: '5px',
    fontFamily:'IBM Plex Sans, sans-serif'
  },
  separator: {
    height: '0.5px',
    backgroundColor: '#A5A5A5',
    margin: '10px 0',
    width: '85%',
    fontFamily:'IBM Plex Sans, sans-serif'
  },
};

const Rounded = () => (
  <div style={styles.rounded}></div>
);

const OrganizerDetails = ({ event }) => (
  <div style={{ padding: '0 35px', position: 'absolute', top: '2.5%' }}>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <div style={styles.headerTwo}>{event.event.event_name}</div>
        <div style={styles.normalText}>By {event.event.supplier ? event.event.supplier.supplier_name : ""}</div>
        <div style={styles.headerTwo}>ID: {event.ticket_id.substring(25)}</div>
      </div>
      <div> 📤</div>
    </div>
  </div>
);

const HeadingText = ({ header, desc, align }) => (
  <div style={{ textAlign: align }}>
    <div style={styles.headerTwo}>{header}</div>
    <div style={styles.normalText}>{desc}</div>
  </div>
);

const EventDetails = ({ event }) => {
  const ticketNo = event.event.classes.reduce((acc, cur) => {
    if (cur.class_id === event.ticketClass) {
      return { ...cur };
    }
    return acc;
  }, {});

  const totalAttendees = event.event.classes.reduce((acc, cur) => acc + (cur.quantity - cur.available), 0);

  return (
    <div style={{ padding: '0 35px', position: 'absolute', top: '23%', width: '83%' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <HeadingText header="Date of Event" desc={event.event.event_date} align="left" />
        <HeadingText header="Time of Event" desc={event.event.event_time} align="right" />
      </div>
      <div style={{ margin: '30px 0' }}>
        <HeadingText header="Location" desc={event.event.location} align="left" />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', margin: '30px 0' }}>
        <HeadingText header="Number of Seats" desc={event.quantity.toString()} align="left" />
        <HeadingText header="Total Attendees" desc={totalAttendees.toString()} align="right" />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', margin: '30px 0' }}>
        <div style={styles.headerTwo}>Type of Seats</div>
        <div style={styles.normalText}>{ticketNo.class_name}</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', margin: '30px 0' }}>
        <div style={styles.headerTwo}>Amount</div>
        <div style={styles.normalText}>{`${event.amount} TZS`}</div>
      </div>
    </div>
  );
};

const ReceiptContainer = () => {

  const pathname = window.location.pathname;

  const updateSalesData = useUpdateUsers();

  const ticketData = useUsers().sales_data;

  const getSalesData = useCallback(() => {
      SalesData(pathname.slice(1))
          .then(data => {
              updateSalesData({type: "SET_SALES_DATA", payload: data})
          })
              .catch(error => {
              console.log(error);
          });
  }, [pathname, updateSalesData]);

  useEffect(() => {
      getSalesData()
  }, [getSalesData]);

  return (
    <div style={{ position: 'relative', height: '65vh', marginTop:  '10vh', width: '100%'}}>
      <div style={styles.container}></div>
      <OrganizerDetails event={ticketData.ticket} />
      <EventDetails event={ticketData.ticket} />
      <div style={{ position: 'absolute', top: '10vh', left: 0, right: 0,  display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={styles.separator}></div>
      </div>
      <div style={{ position: 'absolute', top: '10vh', left: 0 }}>
        <Rounded />
      </div>
      <div style={{ position: 'absolute', bottom: '5.5vh', left: 0, right: 0,  display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={styles.separator}></div>
      </div>
      <div style={{ position: 'absolute', bottom: '8vh', left: 0 }}>
        <Rounded />
      </div>
      <div style={{ position: 'absolute', bottom: '30px', left: 0, right: 0, height: '0px', display: 'flex', justifyContent: 'center', }}>
        <QRCode value={ticketData.ticket.ticket_id} />
      </div>
    </div>
  );
};

export default ReceiptContainer;
