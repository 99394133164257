import './App.css';
import ReceiptContainer from './components';
import { AppProvider } from './hooks';

function App() {
  return (
    <AppProvider>
      <ReceiptContainer />
    </AppProvider>
  );
}

export default App;
