import axios from 'axios';

const url = 'https://swahiliesapi.invict.site/Api/ticket';

// 
export async function SalesData(ticket_id){
    
    let sendingData = {
        code: 116,
        api: 100,
        data: {
            ticket_id,
        }
    }

    try {
        const res = await axios
            .post(url, 
                sendingData
            );
        let data = res.data;
        return data;
    } catch (e) {
        console.log(e);
    }
};