import React, { useCallback, useContext, useReducer } from "react";

// create a context
const UserContext = React.createContext();
const UpdateUserContent = React.createContext();

// export contexts
export function useUsers(){
    return useContext(UserContext);
}
export function useUpdateUsers() {
    return useContext(UpdateUserContent);
}


// 
export function AppProvider({ children }) {

    const [state, dispatch] = useReducer((state, action) => {
            switch (action.type) {
                case "SET_SALES_DATA":
                    return { ...state, sales_data: action.payload};
                default:
                    return state;
            }
        },
        {
            sales_data: {
                "code": 0,
                "ticket": {
                    "ticket_id": "",
                    "event": {
                        "event_id": "",
                        "supplier": {
                            "supplier_id": "",
                            "supplier_name": "",
                            "supplier_number": 0,
                            "phone_number": "",
                            "firebase_token": "",
                            "ig_name": ".",
                            "whatsapp_number": "",
                            "tin_document": ".",
                            "nida_document": ".",
                            "business_license": "",
                            "bank_name": "",
                            "bank_account": "",
                            "account_name": "",
                            "is_active": true,
                            "tembo_account": "",
                            "show_ig": false,
                            "registered": ""
                        },
                        "category": {
                            "category_id": "",
                            "category_name": "",
                            "cover_photo": "",
                            "status": "",
                            "registered": "2024-05-10T16:04:07.144518"
                        },
                        "classes": [
                            {
                                "class_id": "",
                                "class_name": "",
                                "class_description": "",
                                "price": 0,
                                "quantity": 0,
                                "available": 0,
                                "status": "",
                                "registered": "",
                                "event": ""
                            }
                        ],
                        "event_name": "",
                        "event_trailer": "",
                        "event_photo": "",
                        "description": "",
                        "location": "",
                        "event_date": "",
                        "event_time": "",
                        "end_date": "",
                        "status": "",
                        "registered": ""
                    },
                    "price": 0,
                    "amount": 0,
                    "quantity": 0,
                    "status": "",
                    "payment_status": "",
                    "scanned_time": null,
                    "registered": "2",
                    "ticketClass": "",
                    "ticketGroup": "",
                    "user": "",
                    "scanned_by": null
                }
            },
        }
    )

    const updateFunctionData = useCallback((newData) => {
        dispatch(newData);
    }, []);

    return (
        <UserContext.Provider value={state}>
            <UpdateUserContent.Provider value={updateFunctionData} >

                {children}

            </UpdateUserContent.Provider>
        </UserContext.Provider>
    )
}